import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { emphasize, fade, lighten } from '@material-ui/core/styles/colorManipulator'
import Icon from '@material-ui/core/Icon'
import Chip from '@material-ui/core/Chip'

import { SortableHandle } from 'react-sortable-hoc'
import Link from 'shared-ui/components/Link'
import classNames from 'classnames'
import { withLanguage } from '~src/LanguageContext'

const styles = theme => {
  const iconColor = '#ffffff'
  const { unit } = theme.spacing
  const backgroundColor = lighten(theme.palette.primary.main, 0.35)
  const backgroundColorV = lighten(theme.palette.grey300, 0.35)
  const backgroundColorP = lighten(theme.palette.common.orange, 0.15)

  return {
    root: {
      backgroundColor: backgroundColor,
      display: 'flex',
      justifyContent: 'space-between',
    },
    rootV: {
      backgroundColor: backgroundColorV,
    },
    rootP: {
      backgroundColor: backgroundColorP,
    },
    rootN: {
      backgroundColor: 'transparent',
    },
    variantSmall: {
      height: unit * 2,
    },
    chipLabel: {
      overflow: 'hidden',
    },
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
    },
    label: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    clickable: {
      '&:hover': {
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
      '&:focus': {
        backgroundColor: backgroundColor,
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: backgroundColor,
      },
    },
    clickableV: {
      '&:hover': {
        backgroundColor: emphasize(backgroundColorV, 0.12),
      },
      '&:focus': {
        backgroundColor: backgroundColorV,
      },
      '&:active': {
        backgroundColor: backgroundColorV,
      },
    },
    clickableP: {
      '&:hover': {
        backgroundColor: emphasize(backgroundColorP, 0.12),
      },
      '&:focus': {
        backgroundColor: backgroundColorP,
      },
      '&:active': {
        backgroundColor: backgroundColorP,
      },
    },
    nonClickable: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
      '&:active': {
        backgroundColor: 'transparent',
      },
    },
    deleteIcon: {
      marginRight: unit * 0.5,
      color: fade(iconColor, 0.7),
      '&:hover': {
        color: iconColor,
      },
    },
    icon: {
      color: theme.palette.grey400,
      marginRight: unit * 0.25,
    },
    notVisible: {
      backgroundColor: theme.palette.common.orange,
      '&:hover': {
        backgroundColor: emphasize(theme.palette.common.orange, 0.12),
      },
      '&:focus': {
        backgroundColor: emphasize(theme.palette.common.orange, 0.12),
      },
    },
    inactive: {
      backgroundColor: theme.palette.grey300,
      '&:hover': {
        backgroundColor: emphasize(theme.palette.grey300, 0.12),
      },
      '&:focus': {
        backgroundColor: emphasize(theme.palette.grey300, 0.12),
      },
    },
    unpublished: {
      backgroundColor: theme.palette.common.red,
      '&:hover': {
        backgroundColor: emphasize(theme.palette.common.red, 0.12),
      },
      '&:focus': {
        backgroundColor: emphasize(theme.palette.common.red, 0.12),
      },
    },
  }
}

const DragHandle = withStyles(styles)(
  SortableHandle(({ classes }) => (
    <Icon
      onMouseDown={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
      className={classes.deleteIcon}
    >
      drag_handle
    </Icon>
  )),
)

const translateItem = (element, selectedLanguage) => {
  return {
    ...element,
    title: element[`title__${selectedLanguage.id}`] || element['title'],
    _name: element[`_name__${selectedLanguage.id}`] || element['_name'],
  }
}

const ReferenceChip = withLanguage(
  withStyles(styles)(
    ({
      classes,
      className,
      style,
      value,
      settings = {},
      onDelete,
      sortable,
      link = true,
      variant = 'normal',
      selectedLanguage,
      ...rest
    }) => {
      const { collection, detail, popup, isSimpleRef } = settings

      let {
        name,
        _name,
        title = _name || name,
        _id,
        publishedAt,
        enabled,
        _status,
        _nameAlt,
      } = translateItem(value, selectedLanguage)

      if (isSimpleRef) {
        link = false
        _name = _nameAlt
        style = ''
      }

      const linkProps = {}
      if (link && collection && style !== 'link') {
        linkProps.component = Link

        // TODO we need a better check
        if (popup || collection.startsWith('mod')) {
          linkProps.to = `#~/${detail || collection}/${_id}?page=true`
        } else linkProps.to = `/~/${detail || collection}/${_id}`
      }

      const deleteProps = {}
      if (onDelete) {
        deleteProps.onDelete = e => {
          e.preventDefault()
          e.stopPropagation()
          onDelete && onDelete(e)
        }
      }

      const isLink = style === 'link'
      const unpublished = publishedAt === null
      const notVisible = !unpublished && enabled === false

      const dir = selectedLanguage && selectedLanguage.rtl ? 'auto' : undefined

      const unverified = ['pending', 'candidate'].includes(_status)
      return (
        <Chip
          classes={{
            root: classNames(classes.root, {
              [classes.rootV]: isLink,
              [classes.rootP]: unverified,
              [classes.rootN]: isSimpleRef,
            }),
            label: classes.chipLabel,
            deleteIcon: classes.deleteIcon,
            clickable: classNames(classes.clickable, {
              [classes.clickableV]: isLink,
              [classes.clickableP]: unverified,
              [classes.nonClickable]: isSimpleRef,
            }),
          }}
          className={classNames(className, {
            [classes.variantSmall]: variant === 'small',
            [classes.unpublished]: unpublished,
            [classes.notVisible]: !unpublished && notVisible,
            [classes.inactive]: _status === 'inactive',
          })}
          clickable
          onClick={e => {
            e.stopPropagation()
          }}
          label={
            <div className={classes.labelContainer}>
              {sortable && <DragHandle />}
              {unpublished && (
                // TODO : label ids shouldn't be hardcoded
                <Icon title={'No published version'} fontSize="small" className={classes.icon}>
                  error
                </Icon>
              )}
              {notVisible && (
                <Icon title={'Not visible'} fontSize="small" className={classes.icon}>
                  visibility_off
                </Icon>
              )}
              <span dir={dir} className={classNames(classes.label)}>
                {_name || title}
              </span>
            </div>
          }
          {...deleteProps}
          {...linkProps}
          {...rest}
        />
      )
    },
  ),
)

export { translateItem }
export default ReferenceChip
