import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import DropDown from './SegmentationQuery/DropDown'
import CustomMenuField from './CustomMenuField'
import CustomNumber from './CustomNumber'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
})

class CustomUnknowTextField extends Component {
  render() {
    const { classes, rest } = this.props

    if (this.props.type === 'Enum') {
      return <CustomMenuField {...this.props} />
    }

    if (this.props.type === 'Integer') {
      return <CustomNumber {...this.props} />
    }
    return (
      <TextField
        {...this.props}
        className={classes.textField}
        value={this.props.value}
        helperText={`'${this.props.type}' is not implemented`}
        error
        label={this.props.label}
        disabled
      />
    )
  }
}

CustomUnknowTextField.propTypes = {
  helperText: PropTypes.string,
}

export default withStyles(styles)(CustomUnknowTextField)
