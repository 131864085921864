import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import moment from 'moment'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import FormHelperText from '@material-ui/core/FormHelperText'
import Icon from '@material-ui/core/Icon'
import MaskedInput from 'react-text-mask'
import { withAppFrame } from 'shared-ui/components/AppFrameContext'
import { getMobileDateMask, convertToStandardFormat, getLocaleFormat } from './dateHelper'
import CircularProgress from '@material-ui/core/CircularProgress'

const MaskContext = React.createContext(null)

class TextInputField extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value }
  }

  render() {
    const {
      classes,
      data,
      value,
      className,
      invalid,
      onBlur,
      onFocus,
      tabIndex,
      tipRender,
      fullWidth,
      disabled,
      theme,
      autoFocus,
      customStyle,
      pending,
      hasExternalValidation,
    } = this.props

    let {
      id,
      readOnly,
      placeholder,
      required,
      pattern,
      multiline,
      type,
      rows,
      max,
      min,
      size,
      inputMode,
    } = data

    let errorText
    if (this.props.errorText) {
      errorText = this.props.errorText
    } else {
      errorText = data.errorText
    }

    const isPassword = data.type === 'password'
    const inputMask = this.getInputMask()

    let defaultType = type
    let defaultInputMode = inputMode || 'text'
    let inputPlaceholder
    if (isPassword && this.state.showPassword) defaultType = 'text'
    if (type === 'date' && size === 'xs') {
      defaultType = 'text'
      defaultInputMode = 'numeric'
      inputPlaceholder = getLocaleFormat()
    }

    let defaultMax
    if (max === 'now' && type === 'date') {
      defaultMax = convertToStandardFormat(new Date())
    }

    const form = (
      <FormControl
        fullWidth={fullWidth}
        key={id}
        className={className}
        required={required}
        aria-describedby={`${id}-error-text`}
        disabled={readOnly || disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        error={invalid && !disabled}
      >
        {!customStyle && (
          <InputLabel className={'!text-grey-400'} htmlFor={id} required={required} shrink>
            {placeholder}
          </InputLabel>
        )}
        <Input
          style={theme.direction === 'rtl' ? { textAlign: 'right', direction: 'rtl' } : undefined}
          className={customStyle ? 'custom-input-class' : ''}
          disableUnderline={customStyle}
          id={data.id}
          name={data.name}
          multiline={multiline}
          rows={rows || 4}
          rowsMax="8"
          type={defaultType}
          value={isPassword ? this.state.password : undefined}
          defaultValue={value}
          onChange={this._handleChange}
          onKeyUp={e => e.keyCode === 27 && e.target.blur()}
          inputRef={ref => (this.inputRef = ref)}
          inputComponent={inputMask ? TextMaskCustom : undefined}
          inputProps={{
            autoFocus,
            max: defaultMax || max,
            min: min,
            inputmode: defaultInputMode,
            tabIndex: tabIndex,
            pattern: pattern,
            placeholder: customStyle ? placeholder : inputPlaceholder,
            className:
              isPassword && !this.state.showPassword
                ? classes.passwordBullets
                : multiline
                  ? classes.inputTextMulti
                  : classes.inputText,
          }}
          endAdornment={
            <InputAdornment position="end">
              {isPassword ? (
                <>
                  {invalid && <Icon className={'!text-red'}>{'cancel_circle'}</Icon>}
                  <IconButton
                    className="visibility-button"
                    aria-label="Toggle password visibility"
                    onClick={this._handleClickShowPassword}
                    onMouseDown={this._handleMouseDownPassword}
                  >
                    <Icon>{this.state.showPassword ? 'visibility_off' : 'visibility'}</Icon>
                  </IconButton>
                </>
              ) : (
                <>
                  {pending ? (
                    <CircularProgress size={20} className="loading-indicator" />
                  ) : (
                    value &&
                    !errorText &&
                    invalid === false &&
                    hasExternalValidation && <Icon className="text-green">check_circle</Icon>
                  )}
                </>
              )}
            </InputAdornment>
          }
        />
        <FormHelperText id={`${id}-error-text`}>
          {errorText && invalid ? errorText : ''}
        </FormHelperText>
        {tipRender && tipRender({ className: classes.tipBox })}
      </FormControl>
    )

    if (Array.isArray(inputMask))
      return (
        <MaskContext.Provider
          value={{ inputMask: inputMask.filter(Boolean).map(m => (m === '#' ? /\d/ : m)), theme }}
        >
          {form}
        </MaskContext.Provider>
      )
    return form
  }

  blur = () => this.inputRef && this.inputRef.blur()

  getInputMask = () => {
    let mask

    const { data, appFrame } = this.props
    const { type, mask: maskProp, size } = data

    if (Array.isArray(maskProp)) {
      mask = maskProp
    } else if (type === 'tel') {
      const {
        settings: {
          language: { phoneMask: maskSettings },
        },
      } = appFrame
      if (Array.isArray(maskSettings)) {
        mask = maskSettings
        // dynamic length mask
        if (mask.includes('*')) {
          if (this.getValue().length <= mask.filter(m => /\d|#/.test(m)).length)
            mask = mask.filter(v => v !== '*')
          else mask = mask.map(v => (v === '*' ? '#' : v))
        }
      }
    } else if (type === 'date' && size === 'xs') {
      mask = getMobileDateMask()
    }

    if (mask) return mask.map(char => (char === '#' ? /\d/ : char))
  }

  _handleChange = e => {
    this.setState({ value: e.target.value })

    const { onChange } = this.props
    onChange && onChange(e)
  }

  _handleMouseDownPassword = event => {
    event.preventDefault()
  }

  _handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }))
  }

  checkValidity = () => {
    const { data, appFrame } = this.props
    const { type, required, max, minAge, maxAge } = data

    let valid = this.inputRef && this.inputRef.checkValidity()
    if (!valid) return false

    if (type === 'tel') {
      const {
        settings: {
          language: { phoneRgx },
        },
      } = appFrame

      let value = this.getValue()
      if ((required || value.length > 0) && phoneRgx) {
        let rgx = new RegExp(phoneRgx)
        return rgx.test(value)
      }
    }

    if (type === 'date') {
      let value = this.getValue()

      const momentDate = moment(value)

      if (!momentDate.isValid()) return false

      if (max === 'now') {
        if (momentDate.isAfter(moment())) return false
      }

      if (maxAge > 0) {
        const minMoment = moment().subtract(maxAge, 'years')

        if (momentDate.isBefore(minMoment)) return false
      }

      if (minAge > 0) {
        const maxMoment = moment().subtract(minAge, 'years')

        if (momentDate.isAfter(maxMoment)) return false
      }
    }

    return true
  }

  getValue = () => {
    const {
      data: { type, size },
    } = this.props
    const { value = '' } = this.state

    switch (type) {
      case 'tel':
        return value.replace(/[^+\d]/g, '')
      case 'date':
        if (size === 'xs') return convertToStandardFormat(value)
        return value
      default:
        return value
    }
  }
}

const styles = ({ spacing: { unit } }) => ({
  passwordBullets: {
    letterSpacing: 3.8,
  },
  inputTextMulti: {
    marginTop: unit * 2,
  },
  inputText: {},
  visibilityButton: {
    right: -unit / 2,
  },
  tipBox: {
    position: 'absolute',
    top: '100%',
    right: 0,
    marginTop: -20,
    zIndex: 100,
  },
})

const TextMaskCustom = props => {
  let { inputRef, placeholder, ...other } = props

  return (
    <MaskContext.Consumer>
      {({ inputMask: mask, theme }) => (
        <MaskedInput
          {...other}
          ref={ref => {
            inputRef(ref ? ref.inputElement : null)
          }}
          mask={mask}
          placeholderChar={'\u2000'}
          placeholder={
            placeholder || mask.map(m => (typeof m === 'object' ? '\u2000' : m)).join('')
          }
          style={theme.direction === 'rtl' ? { direction: 'ltr', textAlign: 'right' } : undefined}
        />
      )}
    </MaskContext.Consumer>
  )
}

export default withAppFrame(withStyles(styles, { withTheme: true })(TextInputField))
