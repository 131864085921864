import React, { useCallback, useMemo } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Icon,
  IconButton,
  Typography,
} from '@material-ui/core'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import classNames from 'classnames'

const styles = ({ palette, spacing: { unit } }) => ({
  root: {
    '&:not(:hover) $buttons': {
      display: 'none',
    },
  },
  title: {
    fontSize: '1.08rem',
    whiteSpace: 'pre',
  },
  expansionPanel: {
    '&:first-child': {
      borderRadius: 0,
    },
    '&:last-child': {
      borderRadius: 0,
    },
    backgroundColor: palette.grey[100],
  },
  dragHandle: {
    marginRight: unit * 0.5,
    marginLeft: -unit,
    '&:hover': {
      opacity: 0.5,
    },
  },
  buttons: {
    marginTop: -unit,
    marginBottom: -unit,
    marginLeft: 'auto',
    '&>button': {
      marginLeft: -unit * 0.5,
      marginRight: -unit * 0.5,
    },
  },
})

const DragHandle = SortableHandle(({ className }) => (
  <Icon
    onMouseDown={e => {
      e.preventDefault()
      e.stopPropagation()
    }}
    className={className}
  >
    drag_handle
  </Icon>
))

// TODO: probably should be moved somewhere else or ideally received as a prop
const itemElementsByType = [
  {
    key: 'text',
    value: ['elementType', 'sectionTitle', 'titleStyle', 'content'],
    label: 'Text',
  },

  {
    key: 'table',
    value: ['elementType', 'sectionTitle', 'titleStyle', 'table'],
    label: 'Table',
  },

  {
    key: 'image',
    value: ['elementType', 'sectionTitle', 'titleStyle', 'imageCaption', 'link', 'image'],
    label: 'Image',
  },
  {
    key: 'horizontal',
    value: ['elementType', 'sectionTitle', 'titleStyle', 'horizontal'],
    label: 'Horizontal',
  },
  {
    key: 'card',
    value: [
      'elementType',
      'sectionTitle',
      'titleStyle',
      'title',
      'subtitle',
      'cardLayout',
      'link',
      'image',
    ],
    label: 'Card',
  },
  {
    key: 'widecard',
    value: [
      'elementType',
      'sectionTitle',
      'titleStyle',
      'title',
      'subtitle',
      'wideCardLayout',
      'link',
      'image',
    ],
    label: 'Wide Card',
  },
  {
    key: 'texttile',
    value: [
      'elementType',
      'sectionTitle',
      'titleStyle',
      'aspectRatio',
      'bgColor',
      'title',
      'buttonText',
      'bodyText',
      'link',
    ],
    label: 'Text Tile',
  },
  {
    key: 'qrcode',
    value: ['elementType', 'sectionTitle', 'titleStyle', 'url', 'size'],
    label: 'QR Code',
  },
  {
    key: 'boolean',
    value: ['elementType', 'boolean'],
    label: 'Boolean',
  },
]

const learningContentElements = [
  {
    key: 'learningContent',
    value: ['elementType', 'sectionTitle', 'contentSubtitle', 'contentImage'],
    label: '',
  },
]

const ElementsListItem = withStyles(styles)(
  SortableElement(
    ({
      className,
      classes,
      expanded,
      id,
      value: { elementType, sectionTitle } = {},
      sortable,
      onClick,
      onDelete,
      onCopy,
      onCut,
      renderFields,
      elementsStructure,
    }) => {
      let elementsByType = elementsStructure || itemElementsByType

      const itemElement =
        useMemo(() => {
          return elementsByType?.find(x => x.key === elementType)
        }, [elementType]) || {}

      const elements = useMemo(() => itemElement.value || [], [])
      const renderedTitle = sectionTitle ? (
        [
          <b>
            {itemElement.label}
            {'   •   '}
          </b>,
          sectionTitle,
        ]
      ) : (
        <b>{itemElement.label}</b>
      )
      const createHandleButtonClick = useCallback(
        method => e => {
          e.stopPropagation()
          method && method(id, e)
        },
        [id],
      )
      const renderedFields = useMemo(() => renderFields(elements, id), [renderFields, elements])
      const renderedButtons = useMemo(
        () => (
          <div className={classes.buttons}>
            {onCut && (
              <IconButton size={'small'} onClick={createHandleButtonClick(onCut)}>
                <Icon>cut</Icon>
              </IconButton>
            )}
            {onCopy && (
              <IconButton size={'small'} onClick={createHandleButtonClick(onCopy)}>
                <Icon>copy</Icon>
              </IconButton>
            )}
            {onDelete && (
              <IconButton size={'small'} onClick={createHandleButtonClick(onDelete)}>
                <Icon>delete</Icon>
              </IconButton>
            )}
          </div>
        ),
        [onDelete, onCopy, onCut, createHandleButtonClick, classes],
      )

      return (
        <li className={classNames(className, classes.root)}>
          <ExpansionPanel
            classes={{ root: classes.expansionPanel }}
            square
            elevation={0}
            expanded={expanded}
          >
            <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>} onClick={onClick}>
              {sortable && <DragHandle className={classes.dragHandle} />}
              <Typography classes={{ root: classes.title }} align="left" color={'primary'}>
                {renderedTitle}
              </Typography>
              {renderedButtons}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={24}>
                {renderedFields}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </li>
      )
    },
  ),
)

export default ElementsListItem
export { itemElementsByType, learningContentElements }
