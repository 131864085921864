import React from 'react'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import PendingButton from 'shared-ui/components/atoms/PendingButton/PendingButton'
import Pill from 'shared-ui/components/atoms/Pills/Pill'
import Link from 'shared-ui/components/Link'

const styles = ({ palette, breakpoints, spacing: { unit } }) => ({
  container: {
    boxSizing: 'border-box',
    padding: unit * 2,
    paddingTop: unit * 3,
    paddingBottom: unit * 3,
    border: '1px solid',
    borderRadius: 4,
    borderColor: palette.grey200,
  },
  headline: {
    marginBottom: unit,
  },
  spacious: {
    padding: unit * 2,
  },
  button: {
    marginTop: unit * 2,
  },
  cancelButton: {
    color: palette.paper,
    marginRight: unit,
    paddingTop: unit,
    paddingBottom: unit,
    height: 'auto',
    [breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  inactive: {
    pointerEvents: 'none',
    opacity: 0.7,
  },
  buttonPill: {
    marginTop: unit * 2,
    marginRight: 50,
    paddingTop: unit,
    paddingBottom: unit,
    paddingLeft: unit * 1.3333,
    paddingRight: unit * 1.3333,
    borderRadius: 2,
    position: 'relative',
    boxShadow:
      '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
  },
  pill: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
    left: '100%',
  },
  buttonContainer: {
    [breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  secondButton: {
    [breakpoints.down('xs')]: {
      marginTop: unit,
    },
  },
})

const DEFAULT_WIDTH = 360

const PaperBox = withStyles(styles, { withTheme: true })(({
  classes,
  className = '',
  style,
  width,
  square = true,
  fixedWidth,
  buttonLabel,
  buttonName,
  buttonCancelLabel,
  onButtonClick,
  onCancelButtonClick,
  title,
  children,
  elevation,
  buttonTo,
  buttonAlign = 'center',
  variant,
  disabled,
  pending,
  jpoints,
  theme,
  moreComponents,
  buttonStyles,
}) => {
  const variantClass = classes[variant] || ''

  const buttonProps = {}
  if (onButtonClick) {
    buttonProps.onClick = onButtonClick
  } else if (buttonTo) {
    buttonProps.component = Link
    buttonProps.to = buttonTo
  }

  return (
    <Paper
      className={classNames(classes.container, variantClass, className, {
        [classes.inactive]: disabled || pending,
      })}
      square={square}
      style={
        fixedWidth
          ? { ...style, width: fixedWidth }
          : { ...style, width: '100%', maxWidth: width || DEFAULT_WIDTH }
      }
      elevation={0}
    >
      {/* TITLE */}
      {title && (
        <Typography variant="h5" className={classNames(classes.headline)}>
          {title}
        </Typography>
      )}

      {/* CONTENT */}
      {children}

      {/* BUTTON */}
      {buttonLabel && (
        <div align={buttonAlign} className={classes.buttonContainer}>
          {buttonCancelLabel && (
            <PendingButton
              type={'cancel'}
              pending={pending}
              name={buttonName}
              variant="contained"
              color="primary"
              className={classNames(classes.button, classes.cancelButton)}
              onClick={onCancelButtonClick}
            >
              {buttonCancelLabel}
            </PendingButton>
          )}

          {jpoints ? (
            <Button
              variant={'contained'}
              type={'submit'}
              className={classNames(classes.buttonPill, classes.secondButton)}
              name={buttonName}
              {...buttonProps}
              {...buttonStyles}
            >
              {buttonLabel}
              <Pill label={jpoints} placement="l" className={classes.pill} />
            </Button>
          ) : (
            <PendingButton
              type={'submit'}
              pending={pending}
              variant="outlined"
              color="secondary"
              className={classNames(classes.button, classes.secondButton)}
              name={buttonName}
              jpoints={jpoints}
              {...buttonProps}
              {...buttonStyles}
            >
              {buttonLabel}
            </PendingButton>
          )}
        </div>
      )}
      {/* More Components eg: msal*/}
      {moreComponents}
    </Paper>
  )
})

export default withStyles({ withTheme: true })(PaperBox)
