import React from 'react'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { UNIT } from 'shared-ui/assets/styles/theme'

const styles = ({ palette, spacing: { unit } }) => ({
  container: {
    boxSizing: 'border-box',
    // paddingTop: unit * 3,
    // paddingBottom: unit * 3
  },
  bottomPadding: {
    paddingBottom: unit * 2,
  },
  title: {
    padding: unit + 4,
    color: palette.grey500,
  },
})

const PaperWithTitle = withStyles(styles)(({
  classes,
  className = '',
  style,
  bottomPadding = true,
  square,
  elevation,
  title,
  children,
}) => {
  //
  return (
    <Paper
      className={classNames(classes.container, className, {
        [classes.bottomPadding]: bottomPadding,
      })}
      square={square}
      style={style}
      elevation={elevation !== undefined ? elevation : 1}
    >
      {/* TITLE */}
      {title &&
        (typeof title === 'function' ? (
          <div className={classes.title}>{title()}</div>
        ) : (
          <Typography variant="subtitle1" className={classes.title}>
            {title}
          </Typography>
        ))}

      {/* CONTENT */}
      {children}
    </Paper>
  )
})

const PaddedContent = ({ children, ...props }) => (
  <div {...props} style={{ ...props.style, paddingLeft: UNIT * 2, paddingRight: UNIT * 2 }}>
    {children}
  </div>
)

export default PaperWithTitle
export { PaddedContent }
