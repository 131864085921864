import React from 'react'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'

const PasswordVisualClue = props => {
  let {
    password = '',
    patterns: {
      required: requiredPatterns = [],
      optional: { min = 0, patterns: conditionalPatterns = [] } = {},
    } = {},
    labels: {
      required: requiredLabels = [],
      optional: { min: minLabel = '', patterns: conditionalLabels = [] } = {},
    } = {},
  } = props

  const regTest = pattern => new RegExp(pattern).test(password)

  const isAllRequired = min >= conditionalPatterns.length

  return (
    <ul className="space-y-1">
      {requiredPatterns.map((pattern, index) => {
        const fulfilled = regTest(pattern)
        return (
          <li key={index} className="flex items-center space-x-3">
            {
              <Icon
                style={{ fontSize: '20px' }}
                className={fulfilled ? '!text-green' : '!text-red'}
              >
                {fulfilled ? 'check_circle' : 'cancel_circle'}
              </Icon>
            }
            <Typography>{requiredLabels[index]}</Typography>
          </li>
        )
      })}
      {!isAllRequired && <Typography>{minLabel.replace('%s', min)}</Typography>}
      {conditionalPatterns.map((pattern, index) => {
        const fulfilled = regTest(pattern)
        return (
          <li key={index} className="flex items-center space-x-3">
            {
              <Icon
                style={{ fontSize: '20px' }}
                className={
                  fulfilled ? '!text-green' : isAllRequired ? '!text-red' : '!text-primary'
                }
              >
                {fulfilled ? 'check_circle' : isAllRequired ? 'cancel_circle' : 'circle'}
              </Icon>
            }
            <Typography>{conditionalLabels[index]}</Typography>
          </li>
        )
      })}
    </ul>
  )
}

export default PasswordVisualClue
