import React, { useEffect, useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import PaperWithTitle from 'shared-ui/components/atoms/PaperWithTitle'
import { UNIT } from 'shared-ui/assets/styles/theme'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'

const AdminOnBehalfFunctionPage = props => {
  const { data, pMember, api } = props

  // State management for each dropdown
  const [selectedAccount, setSelectedAccount] = useState('')
  const [selectedContract, setSelectedContract] = useState('')
  const [selectedCondition, setSelectedCondition] = useState('')
  const [selectedSku, setSelectedSku] = useState('')
  const [code, setCode] = useState('') // State for the Code text field

  const codeReg = /^[A-Z0-9]{12}$/

  // Automatically select when only one option is available
  const autoSelect = (options, onChange) => {
    if (options.length === 1) {
      onChange(options[0].id)
    }
  }

  useEffect(() => {
    // Automatically select an account if only one is available
    autoSelect(data.accounts, setSelectedAccount)
  }, [data.accounts])

  useEffect(() => {
    // Reset dependent dropdowns and auto-select if applicable
    if (selectedAccount) {
      const contracts = data.accounts.find(acc => acc.id === selectedAccount)?.contracts || []
      setSelectedContract('')
      setSelectedCondition('')
      setSelectedSku('')
      autoSelect(contracts, setSelectedContract)
    }
  }, [selectedAccount])

  useEffect(() => {
    if (selectedContract) {
      const conditions =
        data.accounts
          .find(acc => acc.id === selectedAccount)
          ?.contracts.find(ctr => ctr.id === selectedContract)?.conditions || []
      setSelectedCondition('')
      setSelectedSku('')
      autoSelect(conditions, setSelectedCondition)
    }
  }, [selectedContract, selectedAccount])

  useEffect(() => {
    if (selectedCondition) {
      const skus =
        data.accounts
          .find(acc => acc.id === selectedAccount)
          ?.contracts.find(ctr => ctr.id === selectedContract)
          ?.conditions.find(cond => cond.id === selectedCondition)?.skus || []
      setSelectedSku('')
      autoSelect(skus, setSelectedSku)
    }
  }, [selectedCondition, selectedContract, selectedAccount])

  const availableContracts =
    data.accounts.find(account => account.id === selectedAccount)?.contracts || []
  const availableConditions =
    availableContracts.find(contract => contract.id === selectedContract)?.conditions || []
  const availableSkus =
    availableConditions.find(condition => condition.id === selectedCondition)?.skus || []

  // Handler for Submit button
  const handleSubmit = async () => {
    try {
      const { request } = api.onBehalf({
        member: pMember._id, //
        account: selectedAccount, //
        contractCode: selectedContract, //
        conditionCode: selectedCondition, //
        ean: selectedSku,
        uniqueId: code,
      })
      await request
      setCode('')
    } catch (e) {
      console.error('Error while setting password', e)
    }
  }
  // Determine whether the submit button should be enabled
  const isSubmitDisabled =
    !selectedAccount ||
    !selectedContract ||
    !selectedCondition ||
    !selectedSku ||
    !codeReg.test(code)

  console.log(props)

  return (
    <div style={{ padding: UNIT * 2 }}>
      <Typography variant={'h5'} style={{ marginBottom: '1rem' }}>
        Manual submission - scanning condition
      </Typography>
      <PaperWithTitle style={{ padding: UNIT * 2 }}>
        <Typography variant={'h5'} style={{ marginBottom: '1rem' }}>
          1. Member Details
        </Typography>
        {/* Static Dropdown */}
        <FormControl fullWidth style={{ marginBottom: '1rem' }} disabled>
          <InputLabel id="static-dropdown-label">{'Member'}</InputLabel>
          <Select labelId="static-dropdown-label" value={pMember._id}>
            <MenuItem value={pMember._id}>{pMember.displayName}</MenuItem>
          </Select>
        </FormControl>

        {/* Accounts Dropdown */}
        <FormControl fullWidth style={{ marginBottom: '1rem' }}>
          <InputLabel id="account-select-label">Account</InputLabel>
          <Select
            labelId="account-select-label"
            value={selectedAccount}
            onChange={event => setSelectedAccount(event.target.value)}
          >
            {data.accounts.map(account => (
              <MenuItem key={account.id} value={account.id}>
                {account.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </PaperWithTitle>

      <PaperWithTitle style={{ marginTop: UNIT, padding: UNIT * 2 }}>
        <Typography variant={'h5'} style={{ marginBottom: '1rem' }}>
          2. Contract Details
        </Typography>
        {/* Contracts Dropdown */}
        <FormControl fullWidth style={{ marginBottom: '1rem' }} disabled={!selectedAccount}>
          <InputLabel id="contract-select-label">Contract</InputLabel>
          <Select
            labelId="contract-select-label"
            value={selectedContract}
            onChange={event => setSelectedContract(event.target.value)}
          >
            {availableContracts.map(contract => (
              <MenuItem key={contract.id} value={contract.id}>
                {contract.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Conditions Dropdown */}
        <FormControl fullWidth style={{ marginBottom: '1rem' }} disabled={!selectedContract}>
          <InputLabel id="condition-select-label">Condition</InputLabel>
          <Select
            labelId="condition-select-label"
            value={selectedCondition}
            onChange={event => setSelectedCondition(event.target.value)}
          >
            {availableConditions.map(condition => (
              <MenuItem key={condition.id} value={condition.id}>
                {condition.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* SKUs Dropdown */}
        <FormControl fullWidth style={{ marginBottom: '1rem' }} disabled={!selectedCondition}>
          <InputLabel id="sku-select-label">SKU</InputLabel>
          <Select
            labelId="sku-select-label"
            value={selectedSku}
            onChange={event => setSelectedSku(event.target.value)}
          >
            {availableSkus.map(sku => (
              <MenuItem key={sku.id} value={sku.id}>
                {sku.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </PaperWithTitle>
      <PaperWithTitle style={{ marginTop: UNIT, padding: UNIT * 2 }}>
        <Typography variant={'h5'} style={{ marginBottom: '1rem' }}>
          3. Code Input
        </Typography>
        {/* Code TextField */}
        <TextField
          fullWidth
          label="Code (Alphanumeric)"
          value={code}
          onChange={event => {
            const inputValue = event.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '') // Convert to uppercase and remove invalid characters
            setCode(inputValue.slice(0, 12)) // Limit to 12 characters
          }}
          disabled={!selectedSku} // Only enabled if an SKU is selected
          style={{ marginTop: '1rem' }} // Add some spacing from a previous dropdown
          helperText="Please enter exactly 12 characters"
        />
      </PaperWithTitle>

      {/* Submit Button */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          // gap: '1rem',
          alignItems: 'center',
          marginTop: '2rem',
        }}
      >
        <Icon style={{ color: '#8e8e8e' }}>info</Icon>
        <span style={{ marginLeft: '1rem', fontSize: '12px', color: '#8e8e8e' }}>
          Please make sure the data is correct and that the code submitted is part of the eligible
          SKUs for the account before submitting it.
        </span>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitDisabled} // Disable button until all fields are selected
          style={{
            maxWidth: '40%',
            backgroundColor: isSubmitDisabled ? '#8e8e8e' : '',
            marginLeft: '2rem',
          }}
          fullWidth
        >
          Submit
        </Button>
      </div>
    </div>
  )
}

export default AdminOnBehalfFunctionPage
